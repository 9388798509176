export default {
  env: 'production',
  urls: {
    api: 'https://api.ralli.app',
    chatPlugin: 'https://plugin.ralli.app',
    sockets: 'https://sockets.ralli.app',
  },
  firebase: {
    apiKey: 'AIzaSyBNYZBTJick456sb9LEuyKZa3Bel8juqLM',
    authDomain: 'ralli-ab1e1.firebaseapp.com',
  },
  facebook: {
    appId: '636100780463594',
    permissions: 'pages_show_list,pages_messaging',
    apiUrl: 'https://graph.facebook.com/v6.0',
  },
  sentry: {
    dsn: 'https://2bddd9e3355f48a0bc461caeae721de8@sentry.io/1991166',
  },
  stripe: {
    apiKey: 'pk_live_bsw5mxOY78IYFWMp2t2nM2hI00HasN2toA',
  },
};
